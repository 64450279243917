var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pr-4 pb-4 pl-4",attrs:{"flat":""}},[_c('tab-title',{attrs:{"title":_vm.$tc('subchannels.heading1', _vm.items.length),"tooltip":true}}),_c('v-row',[_c('v-col',{staticClass:"pl-6 pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"loading":_vm.getLoaders.subChannels,"headers":_vm.headers,"items":_vm.items,"item-class":_vm.rowClasses,"search":_vm.search,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"calculate-widths":"","items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},scopedSlots:_vm._u([{key:"item.rapiif_logo",fn:function(ref){
                  var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"35"}},[_c('v-img',{attrs:{"contain":"","src":require(("@/assets/images/portale/" + (item.rapiif_ssid) + "-" + (item.rapiif_id) + ".png")),"lazy-src":require(("@/assets/images/portale/" + (item.rapiif_ssid) + "-" + (item.rapiif_id) + ".png")),"alt":"Channel-Logo"}})],1)]}},{key:"item.rapiif_hookhost",fn:function(ref){
                  var item = ref.item;
return [(item.rapiif_hookhost)?_c('span',[_vm._v(" "+_vm._s(((item.rapiif_hookprotocol) + "//" + (item.rapiif_hookhost) + ":" + (item.rapiif_hookport) + (item.rapiif_hookpath)))+" ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }