<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <tab-title :title="$t('channel.subheading2', {name: channelName}).toString()" :tooltip="true"/>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
                <v-col cols="8">
                    <Form/>
                </v-col>
            </v-row>
            <SubmitAction
                class="mt-5"
                :valid="valid"
                @submit="saveForm"
            />
        </v-form>
        <div v-if="channelId === 31">
            <v-divider class="my-4"/>
            <v-card-title class="pl-0" v-html="$t('subchannels.heading2')"/>
            <v-card-subtitle class="pl-0" v-html="$t('subchannels.subheading2')"/>
            <v-btn
                :color="getColors.error"
                class="white--text"
                :disabled="!getUser.is_superadmin"
                @click="resyncGPrices"
            >
                {{ $t('common.words.start') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import Form from '@/components/Formulare/Formular.vue'
import SubmitAction from '@/components/Formulare/Absenden.vue'
import TTSuperadminChanges from '@/components/Tooltips/TTAenderungenNurSuperAdmin.vue'
import ApiService from '@/services/api.service'
import { mapGetters } from 'vuex'
import channelsService from '@/services/channels.service'

export default {
    name: 'BasicDataTabChannel',
    components: {
        Dialog,
        TabTitle,
        Form,
        SubmitAction,
        TTSuperadminChanges,
    },
    props: {
        items: Object,
        channelId: Number,
        channelName: String,
    },
    data() {
        return {
            dialogTitle: 'Cannot update channel',
            dialogText: '',
            valid: true,
            loader: false,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getForm',
            'getTab',
            'getUser',
            'getDialog',
            'getLoaders',
        ]),
        channel: {
            get() {
                return this.getForm
            },
            set(newVal) {
                this.$store.dispatch('setForms', newVal)
            },
        },
        models: function () {
            const currentUserIsNotSuperadmin = !this.getUser.is_superadmin

            return [
                {
                    label: this.$i18n.t('common.labels.content'),
                    element: 'checkbox',
                    source: 'ss_isterweitert',
                    cols: 6,
                    disabled() {
                        return currentUserIsNotSuperadmin
                    },
                },
                {
                    label: this.$i18n.t('common.labels.free'),
                    element: 'checkbox',
                    source: 'ss_istkostenlos',
                    cols: 6,
                    disabled() {
                        return currentUserIsNotSuperadmin
                    },
                },
                {
                    label: this.$i18n.t('common.labels.multiAccount'),
                    element: 'checkbox',
                    source: 'ss_multiaccount',
                    cols: 6,
                    disabled() {
                        return currentUserIsNotSuperadmin
                    },
                },
                {
                    label: this.$i18n.t('common.labels.reporting'),
                    element: 'checkbox',
                    source: 'ss_getsupportreport',
                    cols: 6,
                    disabled() {
                        return currentUserIsNotSuperadmin
                    },
                },
                {
                    label: this.$i18n.t('common.labels.getBookings'),
                    element: 'checkbox',
                    source: 'ss_getbookings',
                    cols: 6,
                    disabled() {
                        return currentUserIsNotSuperadmin
                    },
                },
            ]
        },
    },
    watch: {
        //Info: Reassign loader, models and form in the store as soon as the tab in member is changed
        '$store.state.tab': function () {
            if (this.getTab === 0) {
                this.channel = this.getChannel
                this.$store.dispatch('setModels', this.models)

            }
        },
        '$store.state.form': {
            deep: true,
            immediate: true,
            handler() {
                this.valid = true
            },
        },
        '$store.state.locale': function () {
            this.$store.dispatch('setModels', this.models)
        },
    },
    methods: {
        resetForm() {
            this.$store.dispatch('restoreForm')
        },
        async saveForm() {

            if (this.valid) {
                await this.$store.dispatch('setLoaders', { submit: true })
                const response = await channelsService.setChannel(this.getForm)

                if (response) {
                    this.channel = response
                    await this.$store.dispatch('setLoaders', { submit: false })
                    await this.$store.dispatch('successfulBanner', `Channel ${ this.channelName } successfully updated!`)

                    this.$socket.emit('broadcastEmitter', {
                        method: 'channelUpdate',
                        force: true,
                        user: this.$store.getters.getUsernameTexts,
                    })
                }
            }
        },
        async resyncGPrices() {

            this.loader = true

            const response = await ApiService.variousActions({
                action: 'resyncGPrices',
            })

            if (response)
                await this.$store.dispatch('successfulBanner', `Channel ${ this.channelName } successfully updated!`)

            this.loader = false
        },
    },
    mounted() {
        this.channel = this.items
        this.$store.dispatch('setModels', this.models)
    },
}
</script>
