<template>
    <div>
        <v-card-title>
            <div class="d-flex flex-row align-end">
                <div class="mb-1" v-if="channelId">
                    <v-card outlined class="pa-3 elevation-0" max-width="100px">
                        <v-img
                            contain
                            :src="require(`@/assets/images/portale/${channelId}.png`)"
                            :lazy-src="require(`@/assets/images/portale/${channelId}.png`)"
                        />
                    </v-card>
                </div>
                <div class="pl-4">
                    {{ channelName }}
                </div>
            </div>
        </v-card-title>
        <v-card-text class="d-flex justify-space-between fill-height">
            <div class="pt-1" v-html="$t('channel.subheading1', {name: channelName})"/>
        </v-card-text>
    </div>
</template>

<script>
import ActAsMemberButton from '@/components/Allgemein/AgiereAlsKundeSchaltflaeche.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'ChannelHeadingDetails',
    components: { ActAsMemberButton },
    props: {
        channelName: String,
        channelId: Number,
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getUser',
            'getColors',
            'getMemberChannel',
        ]),
    },
    methods: {
        getUserFeondiDetails() {

            const member = this.getMember

            let variantText = ''

            if (member.feondi_anzpremium && !member.feondi_anzvariantec)
                variantText = 'Feondi-Variant B'
            else if (member.feondi_anzpremium && member.feondi_anzvariantec)
                variantText = 'Feondi-Variant B/C'
            else if (!member.feondi_anzpremium && member.member_anzobjekte && member.feondi_anzvariantec)
                variantText = 'Feondi-Variant A/C'
            else if (!member.feondi_anzpremium && member.member_anzobjekte && !member.feondi_anzvariantec)
                variantText = 'Feondi-Variant A'
            else
                variantText = 'No Feondi'

            let registeredPropertiesCount = 0
            let registeredProductsText = ''

            if (member.feondi_anzpremium && !member.feondi_anzvariantec) {
                registeredPropertiesCount = member.feondi_anzpremium
                registeredProductsText = registeredPropertiesCount + ' registered properties'
            }
            else if (member.feondi_anzpremium && member.feondi_anzvariantec) {
                registeredPropertiesCount = member.feondi_anzpremium
                registeredProductsText = 'B: ' + member.feondi_anzpremium + ' | C: ' + member.feondi_anzvariantec + ' registered properties | ' + member.feondi_icssyncintervall + ' ICS-Syncs/24h'
            }
            else if (!member.feondi_anzpremium && member.feondi_anzvariantec) {
                registeredPropertiesCount = member.member_anzobjekte
                registeredProductsText = 'A: ' + member.member_anzobjekte + ' | C: ' + member.feondi_anzvariantec + ' registered properites | ' + member.feondi_icssyncintervall + ' ICS-Syncs/24h'
            }
            else if (!member.feondi_anzpremium && member.member_anzobjekte && !member.feondi_anzvariantec) {
                registeredPropertiesCount = member.member_anzobjekte
                registeredProductsText = member.member_anzobjekte + ' registered properties'
            }

            return {
                variantText: variantText,
                registeredPropertiesCount: registeredPropertiesCount,
                registeredProductsText: registeredProductsText,
                totalPropertiesCount: member.member_totalproperties,
                salesPotential: member.member_totalproperties - registeredPropertiesCount,
            }
        },
        getElapsedTimeSinceSyncing(timestamp) {
            if (timestamp)
                return `since ${ moment.unix(timestamp).fromNow() }`
        },
    },
}
</script>
