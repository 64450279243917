<template>
    <div class="d-flex flex-row align-center justify-start pt-6">
        <v-btn
            class="pa-1 white--text"
            :loading="getLoaders.submit"
            :disabled="!valid"
            :color="$store.getters.getColors.success"
            @click="$emit('submit')"
        >
            {{ $t('common.words.save') }}
        </v-btn>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SubmitAction',
    props: {
        valid: Boolean,
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getLoaders',
        ]),
    },
}
</script>
