<template>
  <div class="w-100 pl-4 mb-4">
    <v-card class="pt-2">
      <channel-heading-details :channel-id="channel['ss_id']" :channel-name="channel['ss_name']" />
      <v-tabs
          v-model="tab"
          :background-color="getColors.primary"
          dark
          color="#ffffff"
          center-active
      >
        <v-tabs-slider
            :color="getColors.secondary1"
        />
        <v-tab
            v-for="(tabItem, index) in tabItems"
            :key="`tabItem-${index}`"
            @change="tab = index"
        >
          <v-icon class="mr-1" small>
            {{ tabItem.icon }}
          </v-icon>
          {{ tabItem.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tabItem, index) in tabItems" :key="`tabItem-${index}`">
          <component
              v-bind:is="tabItem.component"
              :channel-id="ssid"
              :channel-name="channel['ss_name']"
              :items="tabItem.items"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChannelHeadingDetails from '@/components/Allgemein/SchnittstellentitelMitDetails.vue'
import BasicDataTabChannel from '@/components/Portale/PortalStammdaten.vue'
import SubChannelsTab from '@/components/Portale/RestApiPortale.vue'
import channelsService from '@/services/channels.service'

export default {
    name: 'Profile',
    components: {
        ChannelHeadingDetails,
        BasicDataTabChannel,
        SubChannelsTab,
    },
    metaInfo: {
        title: 'Channel',
    },
    data() {
        return {
            channel: {},
            subchannels: [],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getLoaders',
            'getTab',
        ]),
        ssid: function () {
            return parseInt(this.$route.params.ssid)
        },
        tab: {
            get() {
                return this.getTab
            },
            set(newValue) {
                this.$store.commit('SET_TAB', newValue)
            },
        },
        tabItems: function () {
            const tabItems = [
                {
                    title: this.$i18n.t('common.tabs.basicData'),
                    icon: 'mdi-view-dashboard-edit',
                    component: 'BasicDataTabChannel',
                    disabled: this.getLoaders.channel,
                    items: this.channel,
                },
            ]

            if (this.ssid === 15) {
                tabItems.push({
                    title: this.$i18n.t('common.tabs.subChannels'),
                    icon: 'mdi-view-dashboard',
                    component: 'SubChannelsTab',
                    disabled: this.getLoaders.subChannels,
                    items: this.subchannels,
                })
            }

            return tabItems
        },
    },
    methods: {
        async getChannel() {
            await this.$store.dispatch('setLoaders', { channel: true })
            this.channel = await channelsService.getChannel({ ssid: this.ssid })
            await this.$store.dispatch('setLoaders', { channel: false })
        },
        async getSubChannels() {
            await this.$store.dispatch('setLoaders', { subChannels: true })
            this.subchannels = await channelsService.getSubChannels()
            await this.$store.dispatch('setLoaders', { subChannels: false })
        },
    },
    async mounted() {
        this.tabItems[0].disabled = false
        await this.getChannel()

        if (this.ssid === 15)
            await this.getSubChannels()
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('resetProps', ['tab'])
        next()
    },
}
</script>
