<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <tab-title :title="$tc('subchannels.heading1', items.length)" :tooltip="true"/>
        <v-row>
            <v-col class="pl-6 pr-6">
                <search
                    :value="search"
                    ref="searchComponent"
                    @input="search = $event"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :loading="getLoaders.subChannels"
                    :headers="headers"
                    :items="items"
                    :item-class="rowClasses"
                    :search="search"
                    :loading-text="$t('common.datatable.loading')"
                    :no-data-text="$t('common.datatable.noData')"
                    :no-results-text="$t('common.datatable.noResults')"
                    calculate-widths
                    :items-per-page="25"
                    :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                    class="elevation-2 ma-3 cursor-pointer"
                >
                    <template v-slot:item.rapiif_logo="{ item }">
                        <v-avatar tile size="35">
                            <v-img
                                contain
                                :src="require(`@/assets/images/portale/${item.rapiif_ssid}-${item.rapiif_id}.png`)"
                                :lazy-src="require(`@/assets/images/portale/${item.rapiif_ssid}-${item.rapiif_id}.png`)"
                                alt="Channel-Logo"
                            />
                        </v-avatar>
                    </template>
                    <template v-slot:item.rapiif_hookhost="{ item }">
                            <span v-if="item.rapiif_hookhost">
                                {{ `${ item.rapiif_hookprotocol }//${ item.rapiif_hookhost }:${ item.rapiif_hookport }${ item.rapiif_hookpath }` }}
                            </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog'
import Search from '@/components/Allgemein/Suche.vue'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'SubChannelsTab',
    components: {
        Search,
        Dialog,
        TabTitle,
    },
    metaInfo: {
        title: 'Subchannels',
    },
    props: {
        items: Array,
        channelId: Number,
        channelName: String,
    },
    data() {
        return {
            search: '',
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getLoaders',
        ]),
        headers: function () {
            return [
                {
                    text: '',
                    value: 'rapiif_logo',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '25px',
                },
                {
                    text: this.$i18n.t('common.labels.id'),
                    value: 'rapiif_id',
                    divider: true,
                    width: '90px',
                },
                {
                    text: this.$i18n.t('common.labels.name'),
                    value: 'rapiif_name',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.webhook'),
                    value: 'rapiif_hookhost',
                    divider: true,
                    width: '200px',
                },
            ]
        },
    },
    methods: {
        rowClasses(item) {
            if (!item.rapiif_active)
                return 'cursor-not-allowed text--disabled'
        },
    },
}
</script>
